import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService as NGXTranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ILang } from './translate.interface';
import { LangEnum } from 'repository';

export const LANG_LIST: ILang[] = [
  { code: 'en', name: 'English', culture: 'en-US', langId: LangEnum.English },
  { code: 'es', name: 'Spanish', culture: 'es-ES', langId: LangEnum.Spanish }
];

const LANG_DEFAULT: ILang = LANG_LIST[0];

@Injectable({ providedIn: 'root' })
export class TranslateService {
  public get changeLangEvent(): Observable<ILang> {
    return this.changeLangEvent$.asObservable();
  }

  public get changeLangEventValue(): ILang {
    return this.changeLangEvent$.getValue();
  }

  public language: ILang;
  private readonly changeLangEvent$ = new BehaviorSubject<ILang>({
    code: 'en',
    name: 'English',
    culture: 'en-US',
    langId: LangEnum.English
  });

  private _languageCode: string;
  private readonly _services: NGXTranslateService[] = [];

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    @Optional() @Inject(REQUEST) private readonly _request: Request
  ) {
  }

  public async initLanguage(): Promise<void> {
    const language: ILang = this._getLanguage();

    for (const service of this._services) {
      initService(service, language);
    }

    this._setLanguage(language);
  }

  public changeLang(code: string | ILang): void {
    const CODE: string = (code as ILang).code ?? (code as string);
    const lang: ILang = this._getFindLang(CODE);
    if (!lang || lang.code === this._languageCode) {
      return;
    }

    this.changeLangEvent$.next(lang);
    this._setLanguage(lang);
  }

  public getLangList(): Observable<ILang[]> {
    return of(LANG_LIST);
  }

  public register(translateService: NGXTranslateService): void {
    this._services.push(translateService);

    const language: ILang = this._getLanguage();
    initService(translateService, language);

    translateService
      .use((language || LANG_DEFAULT).code)
      .subscribe(() => null, console.error);
  }

  public get(name: string): NGXTranslateService | undefined {
    // TODO: fix any
    return this._services.find(
      // tslint:disable-next-line:no-any
      (s) => (s.currentLoader as any)._module === name
    );
  }

  public getJSON(name: string): Promise<Response> {
    const language: ILang = this._getLanguage();

    return (
      fetch(`/assets/i18n/${language.code}/${name}.07102024.json`)
        // tslint:disable-next-line:no-any
        .then((response: any) => response.json())
        .catch((error) => {
          console.error(error);

          return {};
        })
    );
  }

  private _getLanguage(): ILang {
    let language;

    if (language) return language;

    if (isPlatformBrowser(this._platformId)) {
      language = this._getFindLang(getBrowserLang());
    }

    if (isPlatformServer(this._platformId)) {
      try {
        const headers = this._request?.headers;
        const reqLangList: string[] = (
          (headers && headers['accept-language']) ||
          ''
        )
          .split(';')[0]
          .split(',');

        language = LANG_LIST.find(
          (lang: ILang) =>
            reqLangList.indexOf(lang.code) !== -1 ||
            reqLangList.indexOf(lang.culture) !== -1
        );
      } catch (err) {
        language = LANG_DEFAULT;
      }
    }

    language = language || LANG_DEFAULT;

    return language;
  }

  // TODO: fix any
  // tslint:disable-next-line:no-any
  private _getFindLang(code: string): ILang | null | any {
    return code ? LANG_LIST.find((lang: ILang) => lang.code === code) : null;
  }

  private _setLanguage(lang: ILang): void {
    for (const service of this._services) {
      service.use(lang.code);
    }
    if (isPlatformBrowser(this._platformId)) {
      document.documentElement.lang = lang.culture;
    }
    this._languageCode = lang.code;
    this.language = lang;
  }
}

function initService(service: NGXTranslateService, language: ILang): void {
  service.addLangs(LANG_LIST.map((lang: ILang) => lang.code));
  service.setDefaultLang((language || LANG_DEFAULT).code);
}

/**
 * Returns the language code name from the browser, e.g. 'de'
 * source: @ngx-translate
 * @returns string
 */
function getBrowserLang(): string {
  // TODO: fix any
  // tslint:disable-next-line:no-any
  const navigator: any = window && window.navigator;

  if (!navigator) return '';

  const browserLang =
    navigator.language || navigator.browserLanguage || navigator.userLanguage;

  return normalizeBrowserLang(browserLang);
}

function normalizeBrowserLang(lang: string): string {
  if (lang.indexOf('-') !== -1) {
    lang = lang.split('-')[0];
  }

  if (lang.indexOf('_') !== -1) {
    lang = lang.split('_')[0];
  }

  return lang;
}
