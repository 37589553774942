<div style='min-width: 360px; min-height: 240px;position:relative;'>
  <ng-container [ngSwitch]='showAffiliateWindow$|async'>
    <ng-container *ngSwitchCase="'create'">
      <div style='position: relative'>
        <lib-dialog-title [showIcon]='false'></lib-dialog-title>
        <lib-confirm-affiliate-window></lib-confirm-affiliate-window>
        <lib-loading *ngIf='showLoader$|async'></lib-loading>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'exist'">
      <lib-dialog-title [showIcon]='false'></lib-dialog-title>
      <lib-exist-affiliate-window></lib-exist-affiliate-window>
    </ng-container>
    <ng-container *ngSwitchCase="'pending'">
      <lib-dialog-title [showIcon]='false'></lib-dialog-title>
      <lib-pending-affiliate-window></lib-pending-affiliate-window>
    </ng-container>
    <ng-container *ngSwitchCase="'questionnaire'">
      <lib-dialog-title></lib-dialog-title>
      <div class='container-dialog'>
        <lib-step-indicator [steps]='steps' [number]='step'></lib-step-indicator>

        <div style='position: relative; min-height: 360px'>
          <div
            class='primary-form sign-up-form' style='position:relative;margin-top: 0'
          >
            <h2 class='title'>{{ 'please-answer' | translate }}</h2>
            <lib-questionnaire
              [nextButton]="'send-request-to-become-an-affiliate'"
              [form]='formQuestionnaire'
              [showLogin]='false'
              (sendFormEvent)='addNewCode($event)'

            ></lib-questionnaire>

          </div>
        </div>
        <lib-loading *ngIf='showLoader$|async'></lib-loading>

      </div>
    </ng-container>


    <ng-container *ngSwitchCase='PlaidVerificationStatus.Failed'>
      <lib-dialog-title></lib-dialog-title>

      <div class='title-block'>
        <h1>{{'unfortunately-your-identity'|translate}}</h1>
      </div>

      <div class='btn-plaid-block'>
        <button class='primary-btn primary-block-by-center' (click)='tryAgainVerification()'>
          {{'try-again-button'|translate}}
        </button>
      </div>

    </ng-container>
    <ng-container *ngSwitchCase='PlaidVerificationStatus.PendingReview'>
      <lib-dialog-title></lib-dialog-title>

      <div class='title-block'>
        <h1>{{'one-of-our-admins'|translate}}</h1>
      </div>

      <div class='sub-title'>
        {{'the-admin-has-already'|translate}}
      </div>

    </ng-container>
    <ng-container *ngSwitchCase='failedAttemptCounter'>
      <lib-dialog-title></lib-dialog-title>
      <div class='title-block'>
        <h1>{{'unfortunately-you-failed-your-identity-verification'|translate}}</h1>
      </div>
      <div class='sub-title'>
        {{'please-contact-our-live'|translate}}
      </div>


    </ng-container>
    <ng-container *ngSwitchCase='loader'>
      <lib-dialog-title></lib-dialog-title>
      <div class='title-block'>
        <h1>{{'alright-youre-getting-verified'|translate}}</h1>
      </div>

      <div style='position:relative;' class='mb-2'>
        <lib-loading></lib-loading>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase='PlaidVerificationStatus.Pending'>
      <lib-dialog-title></lib-dialog-title>

      <div class='title-block'>
        <h1>{{'please-complete-a-KYC'|translate}}</h1>
      </div>

      <div class='btn-plaid-block'>
        <button class='primary-btn primary-block-by-center' (click)='goToPlaid()'>
          {{'verify-my-identity'|translate}}
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'createCode'">
      <ng-container *ngIf='!(showLoader$|async)'>

        <lib-dialog-title></lib-dialog-title>
        <div class='container-dialog'>
          <lib-step-indicator [steps]='steps' [number]='step'></lib-step-indicator>

          <h2 class='title'>
            {{ 'add-new-affiliate-code' | translate }}
          </h2>
          <form (ngSubmit)='next()' [formGroup]='form'>
            <nz-form-item>
              <nz-form-control [nzErrorTip]='errors.AffiliateCode'>
                <input
                  nz-input
                  type='text'
                  name='AffiliateCode'
                  id='AffiliateCode'
                  class='primary-input'
                  formControlName='AffiliateCode'
                  placeholder='Your referral code to promote TPT'
                />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control [nzErrorTip]='errors.companyName'>
                <input
                  nz-input
                  type='text'
                  name='companyName'
                  id='companyName'
                  maxlength='35'
                  minlength='3'
                  class='primary-input'
                  formControlName='companyName'
                  placeholder='Company name'
                  [readOnly]='isCompanyName'
                />
              </nz-form-control>
            </nz-form-item>


      <nz-form-item>
        <nz-form-control [nzErrorTip]="errors.acceptAffiliateTermsConditions">
          <div class="checkbox-block">
            <input
              nz-input
              type="checkbox"
              name="acceptAffiliateTermsConditions"
              id="acceptAffiliateTermsConditions"
              class="primary-input"
              formControlName="acceptAffiliateTermsConditions"
            />
            <label
            >{{ 'i-have-read-and-agree-to-the' | translate }}
              <a target="_blank" href="/assets/download/TPTAffiliateTC.pdf">{{
                'affiliate-terms-conditions' | translate
                }}</a></label
            >
          </div>
        </nz-form-control>
      </nz-form-item>

            <button type='submit' class='primary-btn sign-up'>
              {{ 'next' | translate }}
            </button>
          </form>
        </div>
      </ng-container>
      <lib-loading *ngIf='showLoader$|async'></lib-loading>

    </ng-container>
  </ng-container>
</div>
